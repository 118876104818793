import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import getWeek from 'date-fns/getWeek'
import isMonday from 'date-fns/isMonday'
import isSunday from 'date-fns/isSunday'
import isTuesday from 'date-fns/isTuesday'
import isWednesday from 'date-fns/isWednesday'
import isThursday from 'date-fns/isThursday'
import isFriday from 'date-fns/isFriday'
import isSaturday from 'date-fns/isSaturday'
import {
  addDays,
  differenceInDays,
  format,
  nextFriday,
  nextMonday,
  nextSunday,
  nextThursday,
  nextTuesday,
  nextWednesday,
  startOfMonth,
} from 'date-fns'
import nextSaturday from 'date-fns/nextSaturday'
import addMonths from 'date-fns/addMonths'
import { hr, enUS } from 'date-fns/locale'

export const getWeeks = (start, end) => {
  const days = eachDayOfInterval({ start: start, end: end })
  let weeks = []
  days.forEach((day, key) => {
    if (key === 0) {
      if (!isMonday(day)) {
        weeks.push({ week: getWeek(day), isFull: false })
      } else {
        weeks.push({ week: getWeek(day), isFull: true })
      }
    } else if (key === days.length - 1) {
      if (!isSunday(day)) {
        weeks.push({ week: getWeek(day), isFull: false })
      } else {
        weeks.push({ week: getWeek(day), isFull: true })
      }
    } else {
      weeks.push({ week: getWeek(day), isFull: true })
    }
  })

  return weeks
}

export const getWeeksMeta = (start, end) => {
  const days = eachDayOfInterval({ start: start, end: end })
  let daysWithMeta = []
  let weeks = []

  days.forEach((day) => {
    daysWithMeta.push({ day: day, week: getWeek(day, { weekStartsOn: 1 }) })
  })

  daysWithMeta.forEach((day) => {
    weeks.push({ week: day.week, isFull: false, days: 0 })
  })

  weeks.forEach((week, key) => {
    daysWithMeta.forEach((day) => {
      if (week.week === day.week) weeks[key].days++
    })
  })

  weeks.forEach((week, index) => {
    if (week.days === 7) weeks[index].isFull = true
  })

  const seen = new Set()
  const filteredWeeks = weeks.filter((el) => {
    const duplicate = seen.has(el.week)
    seen.add(el.week)
    return !duplicate
  })

  return [filteredWeeks]
}

export const getDayNameFromDate = (date, currentLocale) => {
  return format(new Date(date), 'EEEE', {
    locale: currentLocale === 'hr' ? hr : enUS,
  })
}

export const getDayName = (date) => {
  if (isMonday(date)) return 'Monday'
  if (isTuesday(date)) return 'Tuesday'
  if (isWednesday(date)) return 'Wednesday'
  if (isWednesday(date)) return 'Wednesday'
  if (isThursday(date)) return 'Thursday'
  if (isFriday(date)) return 'Friday'
  if (isFriday(date)) return 'Friday'
  if (isSaturday(date)) return 'Saturday'
  if (isSunday(date)) return 'Sunday'
}

export const getFirstDayCurrentMonth = (state) => {
  if (state === 'start') {
    let day = format(startOfMonth(new Date()), 'yyyy-MM-dd')
    day += 'T00:00'

    return day
  }
  if (state === 'current') {
    let todayCurrent = format(new Date(), "yyyy-MM-dd'T'HH:mm").slice(10, 16)
    let day = format(startOfMonth(new Date()), 'yyyy-MM-dd')
    day += todayCurrent

    return day
  }
  if (state === 'end') {
    let day = format(startOfMonth(new Date()), 'yyyy-MM-dd')
    day += 'T23:59'

    return day
  }
}

export const getCurrentDayCurrentMonth = (state) => {
  if (state === 'start') {
    let day = format(new Date(), "yyyy-MM-dd'T'HH:mm").slice(0, 10)
    day += 'T00:00'

    return day
  }
  if (state === 'current') {
    return format(new Date(), "yyyy-MM-dd'T'HH:mm")
  }
  if (state === 'end') {
    let day = format(new Date(), "yyyy-MM-dd'T'HH:mm").slice(0, 10)
    day += 'T23:59'

    return day
  }
}

export const getCurrentDayPreviousMonth = (state) => {
  if (state === 'start') {
    const dayNumberInThisMonth = differenceInDays(
      new Date(),
      startOfMonth(new Date())
    )
    const firstDayOfPreviousMonth = format(
      startOfMonth(addDays(new Date(), -30)),
      'yyyy-MM-dd'
    )
    let day = format(
      addDays(new Date(firstDayOfPreviousMonth), +(dayNumberInThisMonth - 1)),
      "yyyy-MM-dd'T'HH:mm"
    ).slice(0, 10)
    day += 'T00:00'

    return day
  }
  if (state === 'current') {
    let todayCurrent = format(new Date(), "yyyy-MM-dd'T'HH:mm").slice(11, 16)

    const dayNumberInThisMonth = differenceInDays(
      new Date(),
      startOfMonth(new Date())
    )

    let previousMonthTodayCurrent = format(
      addDays(
        new Date(startOfMonth(addDays(new Date(), -30))),
        +dayNumberInThisMonth
      ),
      "yyyy-MM-dd'T'"
    )
    previousMonthTodayCurrent += todayCurrent

    return previousMonthTodayCurrent
  }
  if (state === 'end') {
    const dayNumberInThisMonth = differenceInDays(
      new Date(),
      startOfMonth(new Date())
    )
    const firstDayOfPreviousMonth = format(
      startOfMonth(addDays(new Date(), -30)),
      'yyyy-MM-dd'
    )
    let day = format(
      addDays(new Date(firstDayOfPreviousMonth), +(dayNumberInThisMonth - 1)),
      "yyyy-MM-dd'T'HH:mm"
    ).slice(0, 10)
    day += 'T23:59'

    return day
  }
}

export const getFirstDayPreviousMonth = (state) => {
  if (state === 'start') {
    let day = format(startOfMonth(addDays(new Date(), -30)), 'yyyy-MM-dd')
    day += 'T00:00'

    return day
  }
  if (state === 'current') {
    let todayCurrent = format(new Date(), "yyyy-MM-dd'T'HH:mm").slice(10, 16)
    let day = format(startOfMonth(addDays(new Date(), -30)), 'yyyy-MM-dd')
    day += todayCurrent

    return day
  }
  if (state === 'end') {
    let day = format(startOfMonth(addDays(new Date(), -30)), 'yyyy-MM-dd')
    day += 'T23:59'

    return day
  }
}

export const getPastMonthFirstDay = (date) => {
  if (isMonday(startOfMonth(addMonths(new Date(date), 0))))
    return nextMonday(startOfMonth(addMonths(new Date(date), -1)))
  if (isTuesday(startOfMonth(addMonths(new Date(date), 0))))
    return nextTuesday(startOfMonth(addMonths(new Date(date), -1)))
  if (isWednesday(startOfMonth(addMonths(new Date(date), 0))))
    return nextWednesday(startOfMonth(addMonths(new Date(date), -1)))
  if (isThursday(startOfMonth(addMonths(new Date(date), 0))))
    return nextThursday(startOfMonth(addMonths(new Date(date), -1)))
  if (isFriday(startOfMonth(addMonths(new Date(date), 0))))
    return nextFriday(startOfMonth(addMonths(new Date(date), -1)))
  if (isSaturday(startOfMonth(addMonths(new Date(date), 0))))
    return nextSaturday(startOfMonth(addMonths(new Date(date), -1)))
  if (isSunday(startOfMonth(addMonths(new Date(date), 0))))
    return nextSunday(startOfMonth(addMonths(new Date(date), -1)))
}

export default {
  getWeeks,
  getWeeksMeta,
  getDayName,
  getFirstDayCurrentMonth,
  getCurrentDayCurrentMonth,
  getFirstDayPreviousMonth,
  getCurrentDayPreviousMonth,
  getPastMonthFirstDay,
}
